<template>
  <div class="data-form">
    <iframe :src="src" seamless frameBorder="0" scrolling="auto"></iframe>
  </div>
</template>

<script>
export default {
  computed: {
    src () {
      return {
        production: '',
        lan: 'http://192.168.1.124:8037',
        development: 'http://localhost:8062'
      }[process.env.NODE_ENV]
    }
  }
}
</script>

<style lang="scss" scoped>
.data-form {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  & > iframe {
    flex: 1;
  }
}
</style>
